.chart {
  width: 100%;
  max-width: 1240px;
  min-height: 100vh;
  padding: 0 20px;
  padding-top: 74px;
}

.choose {
  margin-top: 20px;
  position: relative;
}

.chartContent {
  margin: 20px 0;
}
@media (max-width: 768px) {
  .chart {
    width: 100%;
    padding: 0;
    padding-top: 0px;
    /* background: transparent; */
  }
}
