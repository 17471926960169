.selectType {
  display: flex;
}
.currBox {
  position: relative;
}
.activeCurr {
  height: 36px;
  width: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  cursor: pointer;
}
.activeCurr:hover {
  background-color: #f8f8f8;
}
.darkMode:hover {
  background-color: #2c2f36;
}

.currImg {
  width: 24px;
  height: 24px;
}
.currName {
  font-size: 18px;
  font-weight: normal;
}
.dropdownImg {
  width: 12px;
}

.currList {
  display: none;
  position: absolute;
  top: 40px;
  padding: 5px 0;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 6px 12px;
  transition: all 0.3s;
  z-index: 999;
}
.currListShow {
  display: block;
}

.currListItem {
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 30px;
  cursor: pointer;
}
.currListItem:hover {
  background-color: #f8f8f8;
}
.currListItemDark {
  color: #000;
}
.currListItemDark:hover {
  background-color: #f8f8f8;
  color: #000;
}
